//
import {myWorkspaceRequestApi} from "@/api/workspace";
import { ref, reactive, computed, provide, inject } from  'vue';

export const workspacesInitialization = function () {

    const workspaces = ref(undefined)
    const activeWorkspaceId = ref(undefined)
    const activeWorkspace = computed(() => {
        if (typeof workspaces.value !== "undefined" && typeof activeWorkspaceId.value !== "undefined") {
            for (let group in workspaces.value) {
                let ind = workspaces.value[group].findIndex(item => item.id === activeWorkspaceId.value)
                if (ind !== -1)
                    return workspaces.value[group][ind]
            }
        } else if (typeof workspaces.value !== "undefined" && typeof activeWorkspaceId.value === "undefined") {
            for (let group in workspaces.value) {
                if (workspaces.value[group].length > 0)
                    return workspaces.value[group][0]
            }
        }

        return undefined
    })
    const loadWorkspaces = ref(false)

    const getWorkspaces = async function () {
        loadWorkspaces.value = true

        const data = await myWorkspaceRequestApi()

        workspaces.value = reactive(data);

        loadWorkspaces.value = false
    }

    provide("activeWorkspace", activeWorkspace)
    provide("activeWorkspaceId", activeWorkspaceId)
    provide("workspaces", workspaces)
    provide("loadWorkspaces", loadWorkspaces)
    provide("getWorkspaces", getWorkspaces)

    return {
        loadWorkspaces,
        getWorkspaces
    }
}