<template>
  <div class="pxp-range uk-margin">
    <input
      class="uk-range"
      type="range"
      :min="min"
      :max="max"
      :value="modelValue"
      :step="step"
      @input="change"
    />
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Range",
  props: {
    // v-model
    modelValue: {
      type: Number
    },

    // range
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 100
    },
    step: {
      type: Number,
      required: false,
      default: 0.1
    }
  },
  setup(props, { emit }) {
    const change = function(event) {
      emit("update:modelValue", Number.parseFloat(event.target.value));
    };

    return {
      change
    };
  }
};
</script>

<style scoped></style>
