import {getSubscriptionInfo} from "@/api/pay";
import { ref, reactive, computed, provide, inject } from  'vue';


export const subscriptionInitialization = function () {

    const subscription = ref(undefined)

    const subscriptionLoad = ref(false)

    const getSubscriptionData = async function () {
        subscriptionLoad.value = true

        const data = {
            plan: 'member',
            lastUpdate: '2025-05-03T00:00:00.000Z'
        }/*await getSubscriptionInfo()*/

        subscription.value = reactive(data)

        if (subscription.value.plan === 'test' &&
            Date.parse(subscription.value.lastUpdate) + 1209600000 - Date.now() <= 0) {
            localStorage.setItem('testEnd', '1')
        } else {
            localStorage.removeItem('testEnd')
        }

        subscriptionLoad.value = false
    }

    provide("subscription", subscription)
    provide("subscriptionLoad", subscriptionLoad)
    provide("getSubscriptionData", getSubscriptionData)


    return {
        subscriptionLoad,
        getSubscriptionData
    }
}

export const endSubscription = () => localStorage.getItem('testEnd') === '1'
