import axios from "axios";

export async function getSubscriptionInfo () {
    // console.log(axios.defaults.headers.common["Authorization"])
    const response = await axios.post("/subscription-data/me")

    return response.data;
}

export async function getSessionIdCheckout (workspaceId, priceId) {
    const response = await axios.post("/subscription-data/get-checkout-session", {
        workspace: workspaceId,
        priceId: priceId
    })

    return response.data;
}

export async function getCustomerPortalUrl (workspaceId) {
    const response = await axios.post("/subscription-data/get-customer-portal-url", {
        workspace: workspaceId
    })

    return response.data;
}

export async function setNewPlan(plan) {
    let response
    /*
    if (plan === 'basic')
        response = await axios.post("/users/set-plan/basic")
    else if (plan === 'pro')
        response = await axios.post("/users/set-plan/pro")
    else if (plan === 'member')
        response = await axios.post("/users/set-plan/member")
*/
    alert("update plan")
    return response.data
}

export async function updatePaymentMethod() {
    alert("update payment method")
}

export async function getInvoices() {
    const response = await axios.post("/subscription-data/invoices")

    return response.data;
}

export async function getPaymentMethod() {
    const response = await axios.post("/subscription-data/payment-method")

    return response.data;
}
