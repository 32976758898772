<template>
  <div class="canvas">
    <h3 class="dashboard__title">Research Canvas</h3>
    <!-- Load -->

    <template v-if="loadCanvas">
      <CircleLoader class="load"/>
    </template>

    <!-- Main -->

    <template v-else>
      <div class="canvas-grid">
        <DashboardCanvasCard
          v-for="(card, index) in canvasCard"
          :id="'q' + (index + 1)"
          :key="'canvas-card-id-' + card.id"
          :canvas-card="card"
          :canvas-card-data="canvasCardData[index]"
          :style="{ gridArea: 'q' + (index + 1) }"
          :workspace-id="workspaceId"
          @focusout="saveCard(index)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import DashboardCanvasCard from "@/components/DashboardCanvasCard";
import { getCanvasCard, getAllCanvasData, saveCanvasData } from "@/api/canvas";
import { ref, reactive, watch, inject } from "vue";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";


const setup = function(props) {
  const socket = inject('socket')
  const user = inject('user')
  const loadCanvas = ref(true);
  const canvasCard = ref(undefined);
  const canvasCardData = ref(undefined);

  // listen for socket events

  socket.value.on('updated_canvas_data', (data) => {
    const authorId = data.author
    const canvasData = data.data
    if (authorId !== user.id) {
      canvasCardData.value[canvasData.question - 1].data = canvasData.data
    }
  })

  // api call

  const getCanvasCardRequest = async function() {
    canvasCard.value = await getCanvasCard();
  };

  const getCanvasCardDataRequest = async function() {
    const canvasCardResponse = await getAllCanvasData(props.workspaceId)
    canvasCardData.value = canvasCardResponse.map((item) => reactive(item));
  };

  const initCanvas = async function () {
    loadCanvas.value = true
    await getCanvasCardRequest();
    await getCanvasCardDataRequest();
    loadCanvas.value = false
  }

  watch(() => props.workspaceId, (newValue) => {
    initCanvas()
  })

  initCanvas()

  const saveCard = function (index) {
    const card = canvasCardData.value[index]
    saveCanvasData(card.question, props.workspaceId,{ data: card.data })
  }

  return {
    canvasCardData,
    canvasCard,
    saveCard,
    loadCanvas
  };
};

export default {
  name: "TheDashboardCanvas",
  components: { CircleLoader, DashboardCanvasCard },
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup
};
</script>

<style scoped lang="scss">
.canvas {
  .dashboard__title {
    padding: 0 8px;
  }
}

.canvas-grid {
  display: grid;
  box-sizing: border-box;
  grid-template-areas:
    "q4   q8   q10"
    "q2   q6   q5"
    "q9   q11  q3"
    "q7   q13  q12"
    "q1   q1   q1";
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
}

.load {
  width: 100%;
}
</style>
