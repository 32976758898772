<template>
  <i :style="bgImg" class="pxp-icon" />
</template>

<script>
import { computed } from "vue";

export default {
  name: "IconNew",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const bgImg = computed(() => ({
      backgroundImage: `url(${
        require("@/assets/icons/" + props.name + ".svg")
      })`
    }));

    return {
      bgImg
    };
  }
};
</script>

<style scoped>
.pxp-icon {
  display: inline-block;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
