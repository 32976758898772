import '@stripe/stripe-js';
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VueGtag from "vue-gtag-next";

const app = createApp(App)

app.config.warnHandler = () => null;
app.use(router);

app.use(VueGtag, {
    property: {
        id: process.env.VUE_APP_GOOGLE_METRICS
    }
})

app.mount("#app");
