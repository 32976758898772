<template>
  <template v-if="!appLoad">
    <UpdateMessage v-if="appUpdateExist" @app-update="appUpdate"/>
    <router-view />
  </template>
</template>

<script>
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { appInitialization } from "@/setups/initAppSetup";
import LoadingView from "@/views/LoadingView";
import UpdateMessage from "@/components/Global/UpdateMessage";
import OfflineMessage from "@/components/Global/OfflineMessage";
UIkit.use(Icons);

export default {
  setup: appInitialization,
  components: {
    OfflineMessage,
    UpdateMessage,
    LoadingView
  }
};
</script>

<style lang="scss">
@import "~@/assets/fonts/font.scss";
@import "~@/assets/styles/variables.scss";
@import "~uikit/src/scss/variables.scss";
@import "~uikit/src/scss/mixins.scss";
@import "~uikit/src/scss/uikit.scss";
@import "~@/assets/styles/utility.scss";

@import "~@/assets/styles/typograph.scss";

#app,
body,
html {
  @include suppress-scroll;
}

* {
  box-sizing: border-box!important;
}

#app {
  text-align: center;
  color: #2c3e50;
  padding: 0;

}

body {
  background-color: #2a3461;
  margin: 0;
  padding: 0;
  border: 0;
}

.ob__video a {
  color: #0cac60;
  font-weight: bold;
  outline: none;
  text-decoration: none;

  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

.ob__rules p {
  margin: 0;
}

.video-modal {
  .uk-modal-dialog {
    width: 60vw!important;

    // height: 70.3vh!important;
  }

  button[uk-close] {
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 0 4px lightgray;
  }

  video {
    border-radius: 12px;
  }
}

.extended-modal {
  .uk-modal-dialog {
    width: 60vw!important;
  }
}

</style>
