<template>
  <div class="pxp__avatar" :id="id">
    <VueAvatar :username="fullName" :src="src" :size="size"/>
  </div>
</template>

<script>
import VueAvatar from "@/components/UI-Kit/VueAvatar";
import { computed, onMounted, ref, watch } from "vue";

export default {
  name: "Avatar",
  props: {
    id: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: false,
      default: undefined
    },
    size: {
      type: Number,
      required: false,
      default: 24
    },
    src: {
      type: String,
      required: false
    }
  },
  components: {
    VueAvatar
  }
};
</script>

<style scoped lang="scss">
.pxp__avatar {
  margin: 0;

  border-radius: 50%;

  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.avatar__initial {
  font-size: 5rem;
  width: 100%;
  line-height: 100%;
  margin: auto;
  text-align: center;
  font-weight: 500;
}

.avatar__initial-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  place-items: center center;
}

</style>
