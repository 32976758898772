<template>
  <Modal
    v-model:show="show"
    id="todo-today-manage"
    class="extended-modal"
    footer
    header
    padding-not-remove
    is-center
  >
    <template #header>
      <div class="manage-header">
        <h1>What are you up to?</h1>
      </div>
    </template>

    <div class="active-zone">
      <div class="todo-tasks active-section">
        <template v-if="actualTTTasks.length === 0">
          <div class="section-empty"></div>
        </template>
        <draggable
          :list="actualTTTasks"
          group="todo-tasks"
          class="dragzone"
          @change="fixChangeInTodoToday"
          id="todo-tasks-zone"
        >
          <!-- <template #item="{element, index}">
            <ChosenTask :todo-task="element" :index="index"/>
          </template> -->
        </draggable>
      </div>
      <div class="research-question-tasks active-section">
        <template v-if="loadStatus === 'loading'">
          <CircleLoader />
        </template>
        <template v-else-if="loadStatus === 'loaded' && sortedTaskData">
          <draggable
            :list="sortedTaskData"
            class="dragzone"
            :group="{ name: 'todo-tasks', pull: 'clone', put: false }"
            id="sorted-task-data-zone"
          >
            <template #item="{element, index}">
              <SprintsTaskCard mode="todo" class="task-card" :task="element" />
            </template>
          </draggable>
        </template>
      </div>
    </div>

    <template #footer>
      <div class="manage-footer">
        <ButtonNew
          mode="green"
          text="Let’s go!"
          size="l"
          class="button"
          @click="closeModal"
        />
        <p class="hint">
          Hint: 2 or 3 tasks per day are usually enough. Don’t get greedy :)
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import SprintsTaskCard from "@/components/SprintsTaskCard";
// import ChosenTask from "@/components/To do today/ChosenTask";
import { inject, ref, computed, reactive, watch, nextTick } from "vue";
import draggable from "vuedraggable";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import { createTodoTask, deleteTodoTask } from "@/api/todotoday";
import { v1 as uuid } from "uuid";

export default {
  name: "TheDashboardTodoManageModal",
  props: {
    show: Boolean,
  },
  components: {
    CircleLoader,
    ButtonNew,
    Modal,
    SprintsTaskCard,
    // ChosenTask,
    draggable,
  },
  setup(props, { emit }) {
    // Sprint Setup
    const date = inject("date");
    date.value = () => new Date().toISOString();

    const sortedRQData = inject("sortedRQData");
    const loadStatus = inject("loadRQStatus");
    const rqs = inject("rqs");

    const testSTDComp = computed(() => reactive([]));
    const testSTDRef = ref([]);
    const testSTDReactive = reactive([]);

    const sortedTaskData = computed(() =>
      sortedRQData.value ? getSortedTaskData(sortedRQData) : null
    );
    const getSortedTaskData = function(srqd) {
      const std = reactive([]);
      srqd.value.doing.forEach((item) => {
        std.push({ ...item });
      });

      srqd.value.todo.forEach((item) => {
        std.push({ ...item });
      });

      // Сортировка для получения задач, лежащих рядом друг с другом
      std.sort((l, r) => {
        let lrq, rrq;
        lrq =
          typeof l.researchQuestion === "object"
            ? l.researchQuestion.id
            : l.researchQuestion;
        rrq =
          typeof r.researchQuestion === "object"
            ? r.researchQuestion.id
            : r.researchQuestion;

        if (lrq < rrq) return -1;
        else if (lrq > rrq) return 1;
        else return 0;
      });

      std.forEach((item, index) => {
        if (index > 0) {
          let lastItem = std[index - 1];
          let lastItemRq =
            typeof lastItem.researchQuestion === "object"
              ? lastItem.researchQuestion.id
              : lastItem.researchQuestion;
          let itemRq =
            typeof item.researchQuestion === "object"
              ? item.researchQuestion.id
              : item.researchQuestion;
          if (lastItemRq === itemRq) {
            item.researchQuestion = itemRq;
          }
        } else {
          let itemRq =
            typeof item.researchQuestion === "object"
              ? item.researchQuestion.id
              : item.researchQuestion;
          item.researchQuestion = {
            id: itemRq,
            name: rqs[itemRq].name,
          };
        }
      });
      return std;
    };

    // TodoToday Setup
    const createTodoTodayTask = inject("createTTTaskFromSTask");
    const deleteTTTaskById = inject("deleteTTTaskById");
    const actualTTTasks = inject("actualTTTasks");

    // Controllers
    const goTodoTaskBottom = function() {
      nextTick(() => {
        let elem = document.querySelector("#todo-tasks-zone");
        let height = elem.scrollHeight;
        elem.scrollTop = height;
      });
    };

    const fixChangeInTodoToday = function(changeInfo) {
      if (changeInfo.hasOwnProperty("added")) {
        // only tasks
        const { element, newIndex } = changeInfo.added;
        actualTTTasks.splice(newIndex, 1);
        createTodoTodayTask(element);
        goTodoTaskBottom();
      } else if (changeInfo.hasOwnProperty("removed")) {
        const { element } = changeInfo.removed;
        deleteTTTaskById(element.id);
      }
    };

    // Modal setting
    const closeModal = function() {
      emit("update:show", false);
    };

    watch(
      () => props.show,
      (newValue) => {
        emit("update:show", newValue);
      }
    );

    return {
      closeModal,
      fixChangeInTodoToday,
      sortedTaskData,
      loadStatus,
      actualTTTasks,
      testSTDComp,
      testSTDReactive,
      testSTDRef,
    };
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
@import "~@/assets/styles/utility.scss";

.manage-header {
}

.manage-footer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .hint {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;

    color: #000000;

    opacity: 0.5;
  }

  .button {
    margin-right: 16px;
  }
}

.dragzone {
  @include suppress-scroll;
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.section-empty {
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;

  .icon {
    width: 64px;
    height: 64px;

    margin: 72px auto 32px auto;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 72px;
  }
}

.active-section {
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  position: relative;
  margin: 0 5px;
  &.empty {
  }
}

.research-question-tasks {
  .dragzone {
    padding: 0;
    .task-card {
      //border: 1px solid rgba(204, 208, 219, 0.5);
    }
  }
}

.todo-tasks {
  border: 1px dashed #ccd0db;
  .dragzone {
    padding: 0 8px 0 8px;
  }
}

.active-zone {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 350px);
}
</style>
