<template>
  <div class="pxp__dashboard">
      <div class="dashboard__left">
        <TheDashboardLeftCurrentGoals :workspace-id="workspaceId"/>
        <div class="left__divider"></div>
        <TheDashboardLeftCollaborators :workspace-id="workspaceId"/>
      </div>
      <div class="dashboard__main">
        <WorkspaceCard :workspace="workspace" is-dashboard cl @update-title="updateTitle" class="workspace-card" @update-description="updateDescription"/>
        <TheDashboardTodo :workspace-id="workspaceId"/>
        <TheDashboardCanvas :workspace-id="workspaceId"/>
      </div>
  </div>
</template>

<script>
import TheDashboardCanvas from "@/components/TheDashboardCanvas";
import TheDashboardLeftCollaborators from "@/components/TheDashboardLeftCollaborators";
import TheDashboardLeftCurrentGoals from "@/components/TheDashboardLeftCurrentGoals";
import { inject } from 'vue';
import WorkspaceCard from "@/components/Account/WorkspaceCard";
import { updateWorkspace } from "@/api/workspace";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import TheDashboardTodo from "@/components/TheDashboardTodo";

export default {
  name: "Dashboard",
  components: {
    TheDashboardTodo,
    CircleLoader,
    WorkspaceCard,
    TheDashboardLeftCurrentGoals,
    TheDashboardLeftCollaborators,
    TheDashboardCanvas
  },
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const user = inject('user')
    const workspace = inject('activeWorkspace')
    const socket = inject('socket')


    const updateTitle = async function(data) {
      updateWorkspace(props.workspaceId, {
        workName: data
      })
    }

    const updateDescription = async function (data) {
      updateWorkspace(props.workspaceId, {
        workDescription: data
      })
    }

    socket.value.on('update_workspace_data', (data) => {
      const authorId = data.author
      const newWorkspace = data.workspace
      if (user.id !== authorId) {
        workspace.value.workName = newWorkspace.workName
        workspace.value.workDescription = newWorkspace.workDescription
        workspace.value.workCover = newWorkspace.workCover
      }
    })

    return {
      workspace,
      updateTitle,
      updateDescription
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utility.scss";

.pxp__dashboard {
  width: 100vw;
  height: calc(100vh - 48px);
  display: grid;
  grid-template-areas: "dashboard__left dashboard__main";
  grid-gap: 0;
  grid-template-columns: 320px;
  box-sizing: border-box;
}
.dashboard__left {
  @include  suppress-scroll;
  overflow-y: scroll;
  grid-area: dashboard__left;
  margin: 0;
}

.dashboard__main {
  @include suppress-scroll;

  grid-area: dashboard__main;
  background-color: #f4f5f8;
  padding: 20px;
  min-height: 100%;
}

.left__divider {
  width: 272px;
  height: 0;
  margin-left: 24px;

  opacity: 0.2;
  border: 1px dashed #bfc4d6;
}

.load {
  margin: auto;
  margin-top: 120px;
}

.workspace-card {
  margin: 0 8px;
}

</style>

<style>
.partner {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0);
}
.dashboard__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  margin-top: 33px;
  margin-bottom: 17px;
  color: #000000;
}
</style>
