import { ref, provide, computed } from "vue";

import axios from "axios";
import {io} from "socket.io-client";
// import {loadStripe} from '@stripe/stripe-js';
import {useRouter} from "vue-router";

import {workspacesInitialization} from "@/setups/initWorkspaceSetup";
import {userInitialization} from "@/setups/initUserSetup";
import {subscriptionInitialization} from "@/setups/initSubscriptionSetup";

export const appInitialization = function() {
  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_SERVER;

  const { loadingUser, getUserData } = userInitialization();
  const { loadWorkspaces, getWorkspaces } = workspacesInitialization();
  const { subscriptionLoad, getSubscriptionData } = subscriptionInitialization();
  const initLoad = ref(true)
  // const stripeLoad = ref(false)
  // const stripe = ref({})
  const socket = ref()
  const appLoad = computed(() => loadingUser.value || initLoad.value || subscriptionLoad.value /* || stripeLoad.value */ || loadWorkspaces.value)
  const router = useRouter()
  const appUpdateExist = ref(false)
  const appOffline = ref(false)

  const tokenStart = localStorage.getItem("tkn");

  document.addEventListener('appUpdated', (event) => {
    appUpdateExist.value = true
  }, {once: true})

  document.addEventListener('appOffline', (event) => {
    appOffline.value = true
  }, {once: true})

  const appUpdate = function () {
    appUpdateExist.value = false
    window.location.reload();
  }

  const appInit = function() {
    const token = localStorage.getItem("tkn");
    axios.defaults.headers.common["Authorization"] = token

    initLoad.value = true
    // stripeLoad.value = true

    // authorization
    getUserData()
    getWorkspaces()
    getSubscriptionData()
    // socket setting
    socket.value = io(process.env.VUE_APP_BACKEND_SERVER, {
      extraHeaders: {
        authorization: token
      }
    })
    socket.value.on('init', () => {
      initLoad.value = false
    })
    // stripe setting
    /*loadStripe(process.env.VUE_APP_STRIPE_PK)
        .then((stripeObj) => {
          stripe.value = stripeObj
          stripeLoad.value = false
        })*/
  }

  if (tokenStart !== null) {
    axios.defaults.headers.common["Authorization"] = tokenStart

    getUserData()
        .then(() => {
          appInit()
        })
        .catch(() => {
          localStorage.removeItem('tkn')
          delete axios.defaults.headers.common["Authorization"];
          router.push({
            name: 'Auth'
          })
          initLoad.value = false
        })
  } else {
    initLoad.value = false
  }

  provide("socket", socket)
  // provide("stripe", stripe)

  provide('appInit', appInit)
  provide('appLoad', appLoad)

  return {
    appLoad,
    appUpdate,
    appUpdateExist,
    appOffline
  }
};
