<template>
  <div class="research-question-wrapper" :class="[typeof item.researchQuestion === 'string'? 'without-rq': '', 'mode-' + mode]">

    <template v-if="typeof item.researchQuestion !== 'string'">
      <h3 style="{cursor: pointer}" @click="$emit('open-rq-card', item.researchQuestion.id)">{{ item.researchQuestion.name }}</h3>
    </template>

    <div class="task" :class="[item.hasOwnProperty('sync') && item.sync? 'sync': '']">
      <template v-if="!editTaskName">
        <Handler/>
      </template>

      <div class="name">
        <template v-if="editTaskName">
          <input v-model="item.name" placeholder="What will we do?" @focusout="saveTaskName" @keypress.enter="saveTaskName">
        </template>
        <template v-else>
          <p @click="editTaskName = true">{{item.name || 'What will we do?'}}</p>
        </template>
      </div>

      <template v-if="editTaskName">
        <div class="edit-action">
          <IconNew name="key-result-check" class="icon" @click="saveTaskName"/>
          <IconNew name="delete-trash-icon" class="icon" @click="$emit('delete-task')"/>
        </div>
      </template>

      <template v-if="mode === 'todo' && !editTaskName">
        <div class="status" :class="status.class">
          <p>{{ status.data }}</p>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import { ref, computed } from "vue";
import IconNew from "@/components/UI-Kit/IconNew";
import {updateTask} from "@/api/sprint";
import Handler from "@/components/UI-Kit/Handler";

export default {
  name: "SprintsTaskCard",
  components: {Handler, IconNew},
  props: {
    item: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'sprint',
      validate: (mode) => ['sprint', 'todo', 'rq'].findIndex(item => item === mode) !== -1
    }
  },
  setup (props, {emit}) {

    const status = computed(() => {
      if (props.item.move[props.item.move.length - 1].column === 'doing') {
        return {
          data: 'Doing',
          class: 'progress'
        }
      }
      else if (props.item.move[props.item.move.length - 1].column === 'todo') {
        return {
          data: 'TO DO',
          class: 'todo'
        }
      }
    })

    const editTaskName = ref(false)

    const saveTaskName = function () {
      props.item.sync = true
      updateTask(props.item.id, props.item.workspace, props.item)
          .then((data) => {
            props.item.sync = false
          })
      editTaskName.value = false
    }

    return {
      status,
      editTaskName,
      saveTaskName
    }

  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.research-question-wrapper {
  margin-bottom: 8px;
  background: #FFFFFF;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;

  &.without-rq {
    margin-top: -24px;
    &.mode-rq {
      margin-top: 8px;
    }

  }

  &.mode-rq {
    box-shadow: none;
    padding: 0;
    background: none;
  }

  h3 {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0 8px 8px 8px;
  }
}

.task {
  position: relative;
  z-index: 3;
  margin-bottom: 8px;
  background: $pxp-white-color-darken;
  border: 1px solid #E3E5EC;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 8px;

  &.sync {
    background-color: $pxp-white-accent-color-brighter;
  }


  .name {
    width: 100%;
    flex-shrink: 1;

    input, p {
      text-align: left;
      width: 100%;
      border: none;
      outline: none!important;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      color: #000000;
    }

    input {
      border: 1px solid $pxp-gray-color;
      border-radius: 4px;
      padding: 5px;
      background-color: $pxp-white-color-darken;

    }

    p {
      cursor: pointer;
    }

  }

  .edit-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    .icon {
      width: 10px;
      height: 10px;
      margin: 4px;
      cursor: pointer;
    }
  }
}

.status {
  width: auto;
  white-space: pre;
  margin: 0 8px;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: right;
  }

  &.todo p {
    color: $pxp-blue-added-color;
  }

  &.progress p {
    color: $pxp-green-color;
  }
}

.full-rq {
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }

  .rq-tasks {
    h4 {
      text-align: left;
      font-size: 12px!important;
      line-height: 14px;
      margin: 16px 8px 8px 8px;
    }
  }
}
</style>
