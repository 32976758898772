<template>
  <ul
    :id="id"
    class="list-editor"
    :class="'mode-' + mode"
    ref="listElem"
  >
    <template v-if="list.length === 0">
      <p class="list-editor__placeholder">{{placeholder}}</p>
    </template>

    <template v-for="i in list.length">
      <li class="list-editor__item" >
        <textarea
            :placeholder="newItemPlaceholder"
            v-model="list[i - 1]"
            @input="$emit('update:modelValue', list)"
            @focusin="setActive(i - 1)"
            @keydown.enter.shift.prevent
            @keyup.enter.shift.exact.prevent="addLineBreakToItem(i - 1)"
            @keydown.enter.prevent
            @keyup.enter.exact.prevent="addNewItemByIndex(i)"

            @keydown.delete="deleteKeyListener(i - 1)"

            @keydown="adaptHeight(i - 1)"
        />
        <IconNew name="delete-trash-icon" class="list-item__delete-icon" @click.capture="removeItem(i - 1)"/>
        <span/>
      </li>
    </template>
  </ul>
</template>

<script>
import { isRef, watch, ref, toRefs,  reactive, computed, onMounted, toRef, onUpdated, nextTick } from "vue";
import draggable from 'vuedraggable';
import IconNew from "@/components/UI-Kit/IconNew";

/**
 * use to measure delay
 */
const delayListener = function () {
  const start = ref(undefined)

  const delayMeasure = function () {
    const now = Date.now()

    if (typeof start.value === "undefined")
      start.value = now;

    return now - start.value;
  }

  const delayMeasureStop = function () {
    start.value = undefined
  }

  return {
    delayMeasure,
    delayMeasureStop
  }
}

export default {
  name: "ListEditor",
  components: { IconNew, draggable },
  props: {
    modelValue: {
      type: Array
    },
    id: {
      type: String
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    newItemPlaceholder: {
      type: String,
      required: false,
      default: "Type here"
    },
    mode: {
      type: [String || undefined],
      required: false,
      default: 'default'
    }
  },
  setup (props, { emit }) {
    const list = ref(props.modelValue)
    const listElem = ref(undefined)

    // Utility methods

    const updateModelValue = function () {
      emit('update:modelValue', list)
    }

    const setFocusByIndex = function (index) {
      if (index >= 0) {
        listElem.value.querySelector(`li:nth-child(${index + 1}) textarea`).focus()
      }
    }

    const addModifier = function (index, mode, time = -1) {
      const item = getListItemElem(index)
      item.classList.add('list-editor__item_' + mode)
      if (time !== -1) {
        setTimeout(() => {
          item.classList.remove('list-editor__item_' + mode)
        }, time)
      }
    }

    const getListItemElem = function (index, tag="") {
      return listElem.value.querySelector(`li:nth-child(${index + 1}) ${tag}`)
    }

    // List actions

    const addNewItem = function () {
      list.value.splice(list.value.length, 0, "")
      updateModelValue()

      nextTick(() => {
        addModifier(list.value.length - 1, "new-item", 2000)
        setFocusByIndex(list.value.length - 1)
      })
    }

    const addNewItemByIndex = function (index) {
      list.value.splice(index, 0, "")
      updateModelValue()

      nextTick(() => {
        addModifier(index, "new-item", 2000)
        setFocusByIndex(index)
      })
    }

    const removeItemByIndex = function (index) {
      list.value.splice(index, 1)
      updateModelValue()
    }

   // const {delayMeasure, delayMeasureStop} = delayListener()

    const removeItem = function (index) {
      removeItemByIndex(index)
    }

    const deleteKeyListener = function (index) {
      const span = getListItemElem(index, "span")

      span.style.height = "auto"
    }

    const setActive = function (index) {
      // Остальные элементы - не активные
      setInactive()

      // Делаем элемент активным
      const icon = getListItemElem(index, "i")
      icon.classList.add("list-item__delete-icon_active")
    }

    const setInactive = function () {
      for (let i = 0; i < list.value.length; i++) {
        const icon = getListItemElem(i, "i")

        icon.classList.remove("list-item__delete-icon_active")

      }
    }

    // Textarea actions

    const addLineBreakToItem = function (index) {
      list.value[index] += "\n"

      // To support line breaks
      const span = getListItemElem(index, "span")
      span.style.height = span.clientHeight + 16 + "px"

      adaptHeight(index) // because keyup events after keydown
      updateModelValue()
    }

    const adaptHeight = function (index) {
      const itemElem = getListItemElem(index)
      const textarea = itemElem.querySelector('textarea')
      const span = itemElem.querySelector('span')

      span.textContent = textarea.value
      textarea.style.height = span.clientHeight + "px"
    }

    onUpdated(() => {
      for (let i = 0; i < list.value.length; i++) {
        adaptHeight(i)
      }
    })

    onMounted(() => {
      for (let i = 0; i < list.value.length; i++) {
        adaptHeight(i)
      }
    })

    return {
      list, listElem,
      addNewItem, addNewItemByIndex, removeItemByIndex,
      removeItem, deleteKeyListener,
      addLineBreakToItem, adaptHeight,
      setActive, setInactive
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.list-editor {
  padding: 0;
  margin: 0;

  p {
    color: #acacac;
    line-height: 1.2;
  }
}

.list-editor__item {
  position: relative;
  list-style: none;
  padding: 1px 0;
  display: flex;
  flex-direction: row;

  /* list item modification */

  &_delete-challenger {
    /* while holding down the backspace key if textarea is empty */
    border-left: 5px solid white;
    padding-left: 5px;
    animation: delete-challenger 1400ms 1;
  }
  
  &_new-item {
    /* when creating a new list item */

    textarea {
      animation: new-item-added 2000ms 1;
    }
  }

  /* textarea style */

  textarea {
    overflow: hidden;
    resize: none;
    min-height: 20px;

    &:active,
    &:focus {
      border: none!important;
      outline: none!important;
    }
  }

  span {
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: pre-line;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  textarea,
  span {
    display: block;
    width: 100%;
    border: none;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 2px 0;
  }
}

.list-item__delete-icon {
  display: none;
  width: 16px;
  height: 16px;
  &_active {
    display: inline-block;
  }
}

@keyframes new-item-added {
  from {
    background-color: $pxp-white-accent-color-brighter;
  }
  to {
    background-color: $pxp-white-color;
  }
}

@keyframes delete-challenger {
  from {
    border-left-color: $pxp-white-color;
  }
  to {
    border-left-color: $pxp-red-color-darken;
  }
}

</style>
