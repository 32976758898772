import { ref, reactive /*, toRefs , computed watch, inject,*/ } from "vue";

import {
  loginRequestApi,
  registrationRequestApi,
  forgotPasswordRequestApi, resetPasswordRequestApi
} from "@/api/auth";
import axios from "axios";
import * as emailValidator from "email-validator";
import * as PasswordValidator from "password-validator";

const localStorageErrorMessage = `The request violates a policy decision, or the origin is not a valid scheme/host/port tuple.
For example, you may have in your browser configured to deny permission to persist data for the specified origin.`;

const passwordSchema = new PasswordValidator()

passwordSchema
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits(1)
    .has().not().spaces()

class ValidationError extends Error {
  constructor(msg) {
    super(msg);
    this.name = "ValidationError";
  }
}

export const authSetup = function() {
  const email = ref("");
  const password = ref("");
  const code = ref("");
  const userData = reactive({
    university: "",
    name: ""
  });
  let token = "";

  const login = async function() {
    if (!checkEmail())
      throw new ValidationError("Please enter a valid email")

    // Api request
    const loginResponse = await loginRequestApi(email.value, password.value);

    // Set to appState
    token = "Bearer " + loginResponse.jwt;

    // Set token to localStorage
    try {
      localStorage.setItem("tkn", token);
    } catch (err) {
      throw new Error(localStorageErrorMessage);
    }

    // And set to axios
    axios.defaults.headers.common["Authorization"] = token;
  };

  const registration = async function() {

    if (!userData.name.length > 0 && userData.name.split().length >= 2)
      throw new ValidationError("Please provide your full name")

    if (!checkEmail())
      throw new ValidationError("Please enter a valid email")

    if (!validatePassword())
      throw new ValidationError("Please enter a strong password following the recommendations")

    const fullName = userData.name.split(" ");

    const lastName = fullName.pop();
    const firstName = fullName.join(" ");

    const university = userData.university;

    // Api request
    const response = await registrationRequestApi(email.value, password.value, {
      firstName,
      lastName,
      university
    });

    return true;
  };

  const checkEmail = function() {
    return emailValidator.validate(email.value);
  };

  const validatePassword = function () {
    return passwordSchema.validate(password.value);
  }

  const forgotPassword = async function() {
    if (!checkEmail())
      throw new ValidationError("Please enter a valid email")

    await forgotPasswordRequestApi(email.value);
  };

  const resetPassword = async function() {
    if (!validatePassword())
      throw new ValidationError("Please enter a strong password following the recommendations")

    console.log(code.value)
    await resetPasswordRequestApi(code.value, password.value);
  }

  return {
    email,
    password,
    code,
    userData,
    login,
    registration,
    forgotPassword,
    resetPassword
  };
};

export const authFormValidateSetup = function() {
  const fullNameValidate = [
      fullName => {
        return fullName.split(" ").length >= 2? "" : "Please enter a full name(!)"
      }
  ]

  const emailValidate = [
    email => {
      return emailValidator.validate(email)? "" : "Please enter a valid email address";
    }
  ];

  const passwordValidate = [
    password => {
      const schema = new PasswordValidator();
      schema.is().min(8); // Minimum length 8
      return schema.validate(password) ? "" : "Minimum length 8";
    },
    password => {
      const schema = new PasswordValidator();
      schema.is().max(100); // Max length 100
      return schema.validate(password) ? "" : "Max length 100";
    },
    password => {
      const schema = new PasswordValidator();
      schema.has().uppercase(); // Must have uppercase letters
      return schema.validate(password) ? "" : "Must have uppercase letters";
    },
    password => {
      const schema = new PasswordValidator();
      schema.has().lowercase(); // Must have lowercase letters
      return schema.validate(password) ? "" : "Must have lowercase letters";
    },
    password => {
      const schema = new PasswordValidator();
      schema.has().digits(1); // Must have at least 1 digit
      return schema.validate(password) ? "" : "Must have at least 1 digit";
    },
    password => {
      const schema = new PasswordValidator();
      schema.has().not().spaces(); // Should not have spaces
      return schema.validate(password) ? "" : "Should not have spaces";
    }
  ];

  return {
    emailValidate,
    passwordValidate,
    fullNameValidate
  };
};

export const logout = function() {
  localStorage.clear();
  axios.defaults.headers.common["Authorization"] = "";
  window.location.reload();
};

export const checkAuth = () => axios.defaults.headers.common["Authorization"] && axios.defaults.headers.common["Authorization"].length > 0