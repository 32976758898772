import {Image} from "@/components/UI-Kit/Helper classes/image";
import {userInfoRequestApi} from "@/api/auth";
import { ref, reactive, provide, computed } from "vue";


export const userInitialization = function() {
    const loadingUser = ref(false);

    const user = reactive({
        id: "",
        firstName: "",
        lastName: "",
        avatar: {},
        biography: "",
        university: "",
        email: "",
        fullName: computed(() => user.firstName + " " + user.lastName),
        shortFullName: computed(
            () => user.firstName + " " + user.lastName[0] + "."
        ),
        avatarSmall: computed(() => {
            if (user.avatar && user.avatar.formats && user.avatar.formats.small && user.avatar.formats.small.url) {
                return new Image(
                    user.avatar.formats.small.url,
                    user.firstName + "'s photo"
                );
            }
        }),
        avatarThumbnail: computed(() => {
            if (
                user.avatar &&
                user.avatar.formats &&
                user.avatar.formats.thumbnail &&
                user.avatar.formats.thumbnail.url
            ) {
                return user.avatar.formats.thumbnail.url
            }
        })
    });

    const getUserData = async function() {
        loadingUser.value = true;
        try {
            const userData = await userInfoRequestApi();
            user.firstName = userData.firstName;
            user.lastName = userData.lastName;
            user.avatar = userData.avatar;
            user.biography = userData.biography;
            user.university = userData.university;
            user.email = userData.email;
            user.id = userData.id;
        } catch (err) {
            loadingUser.value = false;
            throw new Error('token invalid')
        }
        loadingUser.value = false;
    };

    provide("user", user);
    provide("getUserData", getUserData);

    return {
        loadingUser,
        getUserData
    };
};
