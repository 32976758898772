<template>
  <DashboardLeftSection title="Collaborators">
    <template v-if="loadCollaborators">
      <CircleLoader dark />
    </template>
    <template v-else>
      <template v-if="collaborators.length > 0 || invited.length > 0">
        <DashboardLeftCollaborators
          v-for="user in collaborators"
          :user="user"
        />
        <DashboardLeftCollaborators
          v-for="invite in invited"
          :email="invite.email"
        />
      </template>
      <template v-else>
        <Icon
          :icon="iconCollaborators"
          :size-height="48"
          :size-width="133"
          class="left-item__icon"
        />
        <p class="dli__text">
          Share workspace with supervisor (and other collaborators) to get the most
          out of Page x Page.
        </p>
      </template>
      <Button
        style-modifiers="secondary-dark"
        text="Invite collaborators"
        class="left-item__button collaborators"
        @click="showModal = true"
      />
    </template>

  </DashboardLeftSection>

  <!-- TODO: REFACTOR MODAL! -->
  <Modal
    v-model:show="showModal"
    header footer
    id="invite-collaborators"
    class="invite-modal"
    is-center
  >
    <template v-slot:header>
      <div class="invite-modal-header">
        <h2 class="modal-invite__header">Invite Collaborators</h2>
      </div>
    </template>
    <div class="invite-modal-body">
      <InviteForm v-model="invitedUser" />
    </div>
    <template v-slot:footer>
      <div class="invite-modal-footer">
        <Button text="Invite" class="invite-button" style-modifiers="success" :loading="inviteLoad" @click="invitedUserRequest"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref, reactive, watch, inject } from "vue";
import {inviteCollaboratorsRequestApi, getCollaborators} from "@/api/workspace";

import DashboardLeftSection from "@/components/DashboardLeftSection";
import DashboardLeftCollaborators from "@/components/DashboardLeftCollaborators";
import InviteForm from "@/components/InviteForm";

import { Icon as IconClass } from "@/components/UI-Kit/Helper classes/icon";
import Icon from "@/components/UI-Kit/Icon";
import Button from "@/components/UI-Kit/Button";
import Modal from "@/components/UI-Kit/Modal";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";

export default {
  name: "TheDashboardLeftCollaborators",
  props: {
    workspaceId: String,
  },
  components: {
    DashboardLeftCollaborators,
    CircleLoader,
    InviteForm,
    Modal,
    Icon,
    DashboardLeftSection,
    Button
  },
  setup(props) {
    const iconCollaborators = new IconClass(
      require("@/assets/images/mail-collaborators.svg"),
      "collaborators",
      ""
    );

    const showModal = ref(false);
    const invitedUser = ref("")
    const collaborators = reactive([])
    const invited = reactive([])
    const loadCollaborators = ref(true)
    const inviteLoad = ref(false)
    const socket = inject("socket")
    const user = inject("user")

    const invitedUserRequest = async function () {
      inviteLoad.value = true

      await inviteCollaboratorsRequestApi(props.workspaceId, invitedUser.value)

      const emailRegex = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/g;
      const emailsNewList = [...invitedUser.value.matchAll(emailRegex)].map(item => item[0])
      emailsNewList.forEach(item => {
        if (invited.findIndex(inv => inv.email === item ) === -1)
          invited.push({ email: item })
      })
      inviteLoad.value = false
      showModal.value = false
    }

    socket.value.on('new_collaborator', data => {
      let ind = invited.findIndex(item => item.email === data.user.email)
      invited.splice(ind, 1)
      collaborators.push(data.user)
    })

    socket.value.on('new_invited', data => {
      const authorId = data.author
      const invitedNew = data.data
      if (authorId !== user.id) {
        invited.splice(0, invited.length)
        invitedNew.forEach(item => {
          invited.push(item)
        })
      }
    })

    const initLeftCollaborators = function () {
      loadCollaborators.value = true

      collaborators.splice(0, collaborators.length)
      invited.splice(0, invited.length)

      getCollaborators(props.workspaceId)
          .then((data) => {
            data.collaborator.forEach(item => {
              collaborators.push(item)
            })
            data.invited.forEach(item => {
              invited.push(item)
            })
            loadCollaborators.value = false
          })
    }

    initLeftCollaborators()

    watch(() => props.workspaceId, () => {
      initLeftCollaborators()
    })


    return {
      iconCollaborators,
      showModal,
      invitedUser,
      invitedUserRequest,
      loadCollaborators, collaborators,
      inviteLoad, invited
    };
  }
};
</script>

<style scoped lang="scss">
.dli__text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 175%;
  text-align: left;
  color: #ffffff;

  opacity: 0.7;
}

.left-item__icon {
  margin: 0;
}
.left-item__button {
  border-radius: 32px;
  height: 40px;
  padding: 16px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  margin: 0;
  line-height: 0;
}

.collaborators {
  width: 157px;
}

.modal-invite__header {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  color: #000000;
}

.invite-button {
  width: 140px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.invite-modal {
  &-header {
    padding: 24px 24px 12px 24px;
  }
  &-body {
    padding: 10px 24px 80px 24px;
  }
  &-footer{
    padding: 14px 24px 14px 24px;
  }
}

</style>
