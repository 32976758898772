<template>
  <div class="goal-view-dashboard">
    <div class="header-wrapper">
      <h3 class="header">{{ goal.name }}</h3>
    </div>
    <div>
      <template v-for="(keyResult, index) in goal.keyResult" :key="keyResult.id">
        <template v-if="keyResult.complete">
          <div class="key-result-dashboard complete">
            <IconNew name="ok-dashboard-green" class="complete-icon"/>
            <p>{{keyResult.name}}</p>
          </div>
        </template>
        <template v-else>
          <div class="key-result-dashboard">
            <IconNew name="no-dashboard-gray" class="icon"/>
            <p>{{keyResult.name}}</p>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";
export default {
  name: "DashboardLeftGoal",
  components: {IconNew},
  props: {
    goal: Object
  }
}
</script>

<style scoped lang="scss">
.goal-view-dashboard {
  margin-top: 14px;
  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #FFFFFF;
  }
}
.key-result-dashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  margin-top: 8px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #C0CCFF;
  }

  &.complete p {
    color: #42F6A1;
  }


}

.complete-icon {
  width: 16px;
  height: 16px;
}


.icon {
  width: 4px;
  height: 4px;
  margin: auto 6px auto 4px;
}
</style>
